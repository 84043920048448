import { ArrowBack, MenuOutlined } from "@mui/icons-material";
import { Avatar, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { logout } from "../../auth";
import { deepOrange } from "@mui/material/colors";
import "./appbar.css";

interface AppbarProps { }

const Appbar: React.FC<AppbarProps> = ({ }) => {
    const location = useLocation();
    const [isOnSubPage, setIsOnSubPage] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname !== "/") {
            setIsOnSubPage(true);
        } else {
            setIsOnSubPage(false);
        }
    }, [location]);

    const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleMenuClose();
        logout();
        navigate("/login");
    };

    function handleAction() {
        if (isOnSubPage) {
            navigate("/");
        }
    }

    return (
        <div className="app-bar">
            <div onClick={(e) => handleMenuOpen(e)}>
                    <Avatar sx={{ bgcolor: deepOrange[500] }} sizes="small">
                        S
                    </Avatar>
                </div>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleLogout}>Logg ut</MenuItem>
                </Menu>
            <div className="app-bar-right">
            </div>
        </div>
    );
};

export default Appbar;
