import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';
import './Login.css';
import { login } from '../../auth';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await login(username, password);
      const token = response.access_token;
      const expiration = new Date();
      expiration.setDate(expiration.getDate() + 29);
      localStorage.setItem('token', token);
      localStorage.setItem('token_expiration', expiration.toString());
      localStorage.setItem('username', username);
      setMessage('Login successful.');
      navigate('/');
    } catch (error) {
      setMessage('Login failed.');
    }
  };

  return (
    <Box className="login-container">
      <img src='/logo192.png' alt='logo' className='login-logo' />
      <Typography variant="h6" className="login-title">SiriHurts</Typography>
      <Box className="login-box">
        <form onSubmit={handleSubmit} className="login-form">
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="contained" color="primary" type="submit">
            Login
          </Button>
        </form>
        {message && <Alert severity={message === 'Login successful.' ? 'success' : 'error'}>{message}</Alert>}
      </Box>
      <div className='pingu-container'>
        <img src='/pingu.png' alt='pingu' className='pingu' />
      </div>
    </Box>
  );
};

export default Login;
