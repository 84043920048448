import { apiFetch } from "./apiHelper";
import { Injury, InjuryUpdate } from "./types";

export const fetchInjuries = async () => {
    return apiFetch("/injuries", {
        method: "GET",
    });
};

export const fetchInjury = async (id: string) => {
    return apiFetch(`/injuries/${id}`, {
        method: "GET",
    });
};

export const createInjury = async (injury: Injury) => {
    return apiFetch("/injuries", {
        method: "POST",
        body: JSON.stringify(injury),
    });
};

export const updateInjury = async (id: string, injury: InjuryUpdate) => {
    return apiFetch(`/injuries/${id}`, {
        method: "PUT",
        body: JSON.stringify(injury),
    });
};

export const updateInjuryTimestamp = async (id: string, timestamp: Date) => {
    return apiFetch(`/injuries/${id}/timestamp`, {
        method: "PUT",
        body: JSON.stringify({
            timestamp: timestamp,
        }),
    });
}

export const deleteInjury = async (id: string) => {
    return apiFetch(`/injuries/${id}`, {
        method: "DELETE",
    });
};

export const fetchComments = async (injuryId: string) => {
    return apiFetch(`/injuries/${injuryId}/comments`, {
        method: "GET",
    });
};

export const createComment = async (injuryId: string, content: string) => {
    return apiFetch(`/injuries/${injuryId}/comments`, {
        method: "POST",
        body: JSON.stringify({
            content: content,
            injury_id: injuryId,
        }),
    });
};

export const uploadImages = async (injuryId: string, images: File[]) => {
    const formData = new FormData();
    images.forEach((image) => {
        formData.append("files", image);
    });

    return apiFetch(`/images/${injuryId}`, {
        method: "POST",
        body: formData,
    });
};

export const fetchImage = async (injuryId: string, filename: string) => {
    return apiFetch(`/images/${injuryId}/${filename}`, {
        method: "GET",
    });
};