
const API_URL = process.env.REACT_APP_API_URL;

export const login = async (username: string, password: string) => {
  const response = await fetch(`${API_URL}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({ username, password }),
  });

  if (!response.ok) {
    throw new Error('Failed to login');
  }

  return response.json();
};


export const isTokenValid = (): boolean => {
    const token = localStorage.getItem('token');
    const expiration = localStorage.getItem('token_expiration');
  
    if (!token || !expiration) {
      return false;
    }
  
    const expirationDate = new Date(expiration);
    const now = new Date();
  
    if (now > expirationDate) {
      localStorage.removeItem('token');
      localStorage.removeItem('token_expiration');
      return false;
    }
  
    return true;
  };
  
  export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('token_expiration');
  };
  