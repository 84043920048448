import React, { useState, useEffect } from "react";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import Login from "./components/Login/Login";
import { isTokenValid, logout } from "./auth";
import "./App.css";
import Appbar from "./components/appbar/appbar";
import AddInjury from "./components/Injuries/Injury/Add/AddInjury";
import Main from "./components/Main/Main";
import ViewInjury from "./components/Injuries/Injury/View/ViewInjury";
import { InjuriesProvider } from "./Contexts/InjuriesContext";

const App: React.FC = () => {
	const [loading, setLoading] = useState(true);
	const [isLogedIn, setIsLogedIn] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const checkAuth = () => {
			if (isTokenValid()) {
				setIsLogedIn(true);
			} else {
				logout();
				setIsLogedIn(false);
				navigate("/login");
			}
			setLoading(false);
		};

		checkAuth();
	}, [navigate]);

	if (loading) {
		return (
			<div className="loading-screen">
				<p>Loading...</p>
			</div>
		);
	}

	const background = location.state && location.state.background;

	return (
		<InjuriesProvider>
			{isLogedIn ? (
				<div className="app-container">					
					<div className="app-content">
						<Routes location={background || location}>
							<Route path="/" element={<Main />} />
						</Routes>
						{background && (
							<div className="sub-page">
								<Routes>
									<Route path="/injury/:id" element={<ViewInjury />} />
									<Route path="/injury/new" element={<AddInjury />} />
								</Routes>
							</div>
						)}
					</div>
				</div>
			) : (
				<Routes>
					<Route path="/login" element={<Login />} />
				</Routes>
			)}
		</InjuriesProvider>
	);
};

export default App;
