import React, { useEffect, useState } from "react";
import { Box, Typography, Card, CardContent, Fab, CardMedia, Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "./Injuries.css";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchInjuries } from "../../api";
import { useInjuries } from "../../Contexts/InjuriesContext";
import { Injury } from "../../types";
import { Search } from "@mui/icons-material";
import { getDateString } from "../../helpers";

const Injuries: React.FC = () => {
  const { injuries, setInjuries } = useInjuries();
  const navigate = useNavigate();
  const location = useLocation();
  const [showFab, setShowFab] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredInjuries, setFilteredInjuries] = useState<Injury[]>([]);

  useEffect(() => {
    const loadInjuries = async () => {
      try {
        const data = await fetchInjuries();
        setInjuries(data);
      } catch (error) {
        console.error("Error fetching injuries:", error);
      }
    };
    loadInjuries();
  }, [setInjuries]);

  useEffect(() => {
    setFilteredInjuries(
      injuries.filter((injury) =>
        injury.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        injury.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        injury.location.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, injuries]);

  const handleView = (injury: Injury) => {
    navigate(`/injury/${injury.id}`, { state: { background: true } });
  };

  const handleAdd = () => {
    navigate("/injury/new", { state: { background: true } });
  };

  useEffect(() => {
    setShowFab(location.pathname === "/");
  }, [location]);

  return (
    <div className="injuries-container">
      <div className="injuries-list-container">
        <div className="welcome-message">Hei, Siri!</div>
        <div className="search-container">
          <div className="search-wrapper">
            <Search className="search-icon" />
            <input
              type="text"
              placeholder="Søk"
              className="search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="injuries-list-label">Registrerte skader</div>
        <div className="injuries-list">
          {filteredInjuries.map((injury) => (
            <Card
              key={injury.id}
              className="injury-card"
              onClick={() => handleView(injury)}
              elevation={3}
            >
              {injury.image_urls.length > 0 && (
                <CardMedia
                  className="injury-image"
                  component="img"
                  height="140"
                  src={
                    process.env.REACT_APP_API_URL +
                    `/api/images/${injury.id}/${injury.image_urls[0]}`
                  }
                  alt={injury.title}
                />
              )}
              <CardContent>
                <Typography variant="h6">{injury.title}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {injury.description.substring(0, 100) + (injury.description.length > 100 ? "..." : "")}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {getDateString(injury.timestamp)}
                </Typography>
              </CardContent>
            </Card>
          ))}
          {filteredInjuries.length === 0 && (
            <Box textAlign="center" mt={2}>
              <Typography variant="body2" color="textSecondary">
                Ingen skader funnet
              </Typography>
            </Box>
          )}
        </div>
      </div>
      {showFab && (
        <div className="fab-container">
          <Fab color="primary" aria-label="add" className="fab" onClick={handleAdd}>
            <AddIcon />
          </Fab>
        </div>
      )}
    </div>
  );
};

export default Injuries;
