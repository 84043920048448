import React, { useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Icon,
    IconButton,
    TextField,
} from "@mui/material";
import "./ViewInjury.css";
import { useNavigate, useParams } from "react-router-dom";
import { Injury, Comment } from "../../../../types";
import {
    createComment,
    deleteInjury,
    fetchComments,
    fetchInjury,
    updateInjuryTimestamp,
} from "../../../../api";
import { ArrowBack, Delete, Edit, Send } from "@mui/icons-material";
import { useInjuries } from "../../../../Contexts/InjuriesContext";
import { getDateString } from "../../../../helpers";

type ViewInjuryProps = {};

const ViewInjury: React.FC<ViewInjuryProps> = ({ }) => {
    const nav = useNavigate();
    const { id } = useParams();
    const [injury, setInjury] = React.useState<Injury | null>(null);
    const [comment, setComment] = React.useState("");
    const [comments, setComments] = React.useState<Comment[]>([]);
    const [selectedImage, setSelectedImage] = React.useState<string | null>(null);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [openEditTimestampDialog, setOpenEditTimestampDialog] = React.useState(false);
    const [newTimestamp, setNewTimestamp] = React.useState<Date | null>(null);
    const { loadInjuries } = useInjuries();

    useEffect(() => {
        const loadInjury = async () => {
            try {
                const i = await fetchInjury(id!);
                setInjury(i);
            } catch (error) {
                console.error("Error fetching injury:", error);
            }
        };

        if (id) {
            loadInjury();
        }
    }, [id]);

    useEffect(() => {
        const loadComments = async () => {
            try {
                const data = await fetchComments(injury!.id);
                setComments(data);
            } catch (error) {
                console.error("Error fetching comments:", error);
            }
        };

        if (injury?.id) {
            loadComments();
        }
    }, [injury]);

    const handleAddComment = async () => {
        if (comment) {
            try {
                await createComment(injury!.id, comment);
                const updatedComments = await fetchComments(injury!.id);
                setComments(updatedComments);
                setComment("");
            } catch (error) {
                console.error("Error adding comment:", error);
            }
        }
    };

    const handleDelete = async () => {
        if (injury) {
            try {
                await deleteInjury(injury.id);
                loadInjuries();
                nav(-1);
            } catch (error) {
                console.error("Error deleting injury:", error);
            }
        }
    };

    const openImageView = (imageUrl: string) => {
        setSelectedImage(imageUrl);
    };

    const closeImageView = () => {
        setSelectedImage(null);
    };

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleConfirmDelete = () => {
        handleDelete();
        setOpenDeleteDialog(false);
    };

    const handleOpenEditTimestampDialog = () => {
        setNewTimestamp(injury ? new Date(new Date(injury.timestamp).getTime() - new Date(injury.timestamp).getTimezoneOffset() * 60000) : new Date());
        setOpenEditTimestampDialog(true);
    };

    const handleCloseEditTimestampDialog = () => {
        setOpenEditTimestampDialog(false);
    };

    const handleConfirmEditTimestamp = async () => {
        if (injury && newTimestamp) {
            try {
                await updateInjuryTimestamp(injury.id, newTimestamp);
                const updatedInjury = await fetchInjury(id!);
                setInjury(updatedInjury);
                loadInjuries();
                handleCloseEditTimestampDialog();
            } catch (error) {
                console.error("Error updating timestamp:", error);
            }
        }
    };

    return (
        <div className="injury-view-container">
            <div className="container-header">
                <ArrowBack onClick={() => nav("/")}>Tilbake</ArrowBack>
                <div className="container-header-menu">
                    <Delete onClick={handleOpenDeleteDialog} />
                </div>
            </div>
            <div className="container-content">
                <div className="container-content-top">
                    <div className="injury-details">
                        <div className="injury-timestamp">
                            {getDateString(injury?.timestamp || new Date())}
                            <div className="edit-timestamp">
                                <Edit onClick={handleOpenEditTimestampDialog} fontSize="inherit"/>
                            </div>
                        </div>
                        <div className="injury-title">{injury?.title}</div>

                        <div className="label">Sted</div>
                        <div className="injury-subline">
                            <div className="injury-location">{injury?.location}</div>
                        </div>
                        <div className="label">Beskrivelse</div>
                        <div className="injury-description">{injury?.description}</div>
                    </div>

                    <div className="label">Bilder</div>
                    <div className="image-preview-container">
                        {injury?.image_urls &&
                            injury.image_urls.length > 0 &&
                            injury?.image_urls.map((filename, index) => (
                                <div
                                    key={index}
                                    className="image-preview"
                                    onClick={() => openImageView(filename)}
                                >
                                    <img
                                        src={
                                            process.env.REACT_APP_API_URL +
                                            `/api/images/${injury.id}/${filename}`
                                        }
                                        alt="Preview"
                                    />
                                </div>
                            ))}
                    </div>
                    <div className="label">Kommentarer</div>
                    <div className="comments-list">
                        {comments.map((comment, index) => (
                            <div key={index} className="comment">
                                <div className="comment-user-container">
                                    <div>{comment.username}</div>
                                    <div className="timestamp">
                                        {new Date(comment.timestamp).toLocaleString()}
                                    </div>
                                </div>
                                <div className="comment-message-container">
                                    <div>{comment.content}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="container-content-bottom">
                <div className="add-comment">
                    <TextField
                        label="Legg til kommentar"
                        fullWidth
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        multiline
                        maxRows={3}
                        minRows={1}
                    />
                    <IconButton color="primary" onClick={handleAddComment}>
                        <Send />
                    </IconButton>
                </div>
            </div>

            {selectedImage && (
                <div className="image-overlay" onClick={closeImageView}>
                    <div className="image-modal">
                        <img
                            src={
                                process.env.REACT_APP_API_URL +
                                `/api/images/${injury?.id}/${selectedImage}`
                            }
                            alt="Enlarged"
                        />
                    </div>
                </div>
            )}

            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Bekreft sletting"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Er du sikker på at du vil slette denne skaden? :(
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Avbryt
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                        Slett
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openEditTimestampDialog}
                onClose={handleCloseEditTimestampDialog}
                aria-labelledby="edit-timestamp-dialog-title"
                aria-describedby="edit-timestamp-dialog-description"
            >
                <DialogTitle id="edit-timestamp-dialog-title">
                    {"Oppdater tidspunkt"}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Tidspunkt for skade"
                        type="datetime-local"
                        fullWidth
                        value={
                            newTimestamp
                                ? new Date(newTimestamp.getTime() - newTimestamp.getTimezoneOffset() * 60000).toISOString().slice(0, 16)
                                : ""
                        }
                        onChange={(e) => setNewTimestamp(new Date(e.target.value))}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditTimestampDialog} color="primary">
                        Avbryt
                    </Button>
                    <Button onClick={handleConfirmEditTimestamp} color="primary" autoFocus>
                        Oppdater
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ViewInjury;
