import React, { createContext, useState, useContext, ReactNode, FC } from 'react';
import { Injury } from '../types';
import { fetchInjuries } from '../api';

type InjuriesContextType = {
  injuries: Injury[];
  setInjuries: React.Dispatch<React.SetStateAction<Injury[]>>;
  loadInjuries: () => void;
};

const InjuriesContext = createContext<InjuriesContextType | undefined>(undefined);

export const useInjuries = () => {
  const context = useContext(InjuriesContext);
  if (!context) {
    throw new Error('useInjuries must be used within an InjuriesProvider');
  }
  return context;
};

interface InjuriesProviderProps {
  children: ReactNode;
}

export const InjuriesProvider: FC<InjuriesProviderProps> = ({ children }) => {
  const [injuries, setInjuries] = useState<Injury[]>([]);

  const loadInjuries = async () => {
    try {
      const data = await fetchInjuries();
      setInjuries(data);
    } catch (error) {
      console.error("Error fetching injuries:", error);
    }
  };

  return (
    <InjuriesContext.Provider value={{ injuries, setInjuries, loadInjuries }}>
      {children}
    </InjuriesContext.Provider>
  );
};
