import React, { useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import "./Main.css";
import { useNavigate } from "react-router-dom";
import Injuries from "../Injuries/Injuries";
import Chat from "../Chat/Chat";
import Appbar from "../appbar/appbar";

const Main: React.FC = () => {
  const nav = useNavigate();
  const [emblaRef, emblaApi] = useEmblaCarousel();

  const handleTalkToPainBot = () => {
    emblaApi?.scrollTo(1);
    //handleMenuClose();
  };

  useEffect(() => {
    if (emblaApi) {
      // Embla API is ready
    }
  }, [emblaApi]);


  return (
    <div className="main">
    <Appbar />
    <div className="app-content">      
      <Injuries />
    </div>
    </div>    
  )

  /*
  return (
        <div className="carousel" ref={emblaRef}>
          <div className="carousel__container">
            <div className="carousel__slide">
              <div className="carousel__slide__inner">
                <div className="slide-content">
                  <Injuries />
                </div>
              </div>
            </div>
            <div className="carousel__slide">
              <div className="carousel__slide__inner">
                <div className="slide-content">
                  <Chat />
                </div>
              </div>
            </div>
          </div>
        </div>
  );
  */
};

export default Main;
