import React, { useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  IconButton,
} from "@mui/material";
import {
  ArrowBack,
  PhotoCamera as PhotoCameraIcon,
  RemoveCircle,
} from "@mui/icons-material";
import "./AddInjury.css";
import { createInjury, uploadImages } from "../../../../api";
import { useNavigate } from "react-router-dom";
import { useInjuries } from "../../../../Contexts/InjuriesContext";

const AddInjury: React.FC = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [geoLocation, setGeoLocation] = useState("");
  const [timeOfInjury, setTimeOfInjury] = useState<Date>(new Date(Date.now()));
  const [images, setImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [notifyAndreas, setNotifyAndreas] = useState(false);
  const [newInjuryId, setNewInjuryId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { loadInjuries } = useInjuries(); // Use the context
  const nav = useNavigate();

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      setImages([...images, ...files]);
      setImagePreviews([
        ...imagePreviews,
        ...files.map((file) => URL.createObjectURL(file)),
      ]);
    }
  };

  const removeImage = (index: number) => {
    const newImages = [...images];
    const newPreviews = [...imagePreviews];
    newImages.splice(index, 1);
    newPreviews.splice(index, 1);
    setImages(newImages);
    setImagePreviews(newPreviews);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const newInjury = await createInjury({
        id: "",
        title,
        description,
        location: geoLocation,
        timestamp: new Date(timeOfInjury),
        image_urls: [],
        comments: [],
      });

      if (newInjury.id && images.length > 0) {
        await uploadImages(newInjury.id, images);
      }

      loadInjuries(); // Add the new injury to the context
      setNewInjuryId(newInjury.id);
      setLoading(false);
    } catch (error) {
      console.error("Error creating injury:", error);
    }
  };

  const handleViewInjury = () => {
    if (newInjuryId) {
      nav(`/injury/${newInjuryId}`, { state: { background: true } });
    }
  };

  if (loading) {
    return (
      <div className="add-injury-container">
        <div className="loading-screen">
          <div className="loading-message-image">
            <img src="/pingu_loading.gif" alt="loading" />
          </div>
          <div>Laster...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="add-injury-container">
      {newInjuryId ? (
        <div className="success-message">
          <div className="success-message-image">
            <img src="/pingu_hurt.gif" alt="pingu" />
          </div>
          <div>Skaden er lagt til!</div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleViewInjury}
            sx={{ mt: 2 }}
          >
            Se skade
          </Button>
        </div>
      ) : (
        <>
          <div className="add-injury-container-header">
            <ArrowBack onClick={() => nav(-1)}>Tilbake</ArrowBack>
            <div className="add-injury-container-header-title">
              <div>Legg til skade</div>
            </div>
          </div>
          <div className="add-form">
            <form noValidate autoComplete="off">
              <TextField
                label="Tittel"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                margin="normal"
              />
              <TextField
                label="Beskrivelse"
                fullWidth
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                margin="normal"
              />
              <TextField
                label="Sted"
                fullWidth
                value={geoLocation}
                onChange={(e) => setGeoLocation(e.target.value)}
                margin="normal"
              />
              <TextField
                label="Tidspunkt for skade"
                type="datetime-local"
                fullWidth
                value={new Date(
                  timeOfInjury.getTime() -
                  timeOfInjury.getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .slice(0, 16)}
                onChange={(e) => setTimeOfInjury(new Date(e.target.value))}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
              />
              {imagePreviews.length > 0 && (
                <div className="image-preview-container">
                  {imagePreviews.map((preview, index) => (
                    <div key={index} className="image-preview">
                      <img src={preview} alt="Preview" />
                      <div className="image-remove-button">
                        <IconButton
                          onClick={() => removeImage(index)}
                          color="error"
                        >
                          <RemoveCircle />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <Button
                variant="contained"
                component="label"
                fullWidth
                startIcon={<PhotoCameraIcon />}
                sx={{ mt: 2, mb: 2 }}
              >
                Legg til bilder
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  multiple
                  onChange={handleImageChange}
                />
              </Button>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={notifyAndreas}
                    onChange={(e) => setNotifyAndreas(e.target.checked)}
                  />
                }
                label="Send varsel til Andreas!"
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                sx={{ mt: 2 }}
              >
                Legg til skade
              </Button>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default AddInjury;
